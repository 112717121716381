import React from 'react';
import { FooterBlock, FooterBlockProps } from './footer-block';
import { SocialMediaBlock, SocialMediaBlockProps } from './social-media-block';
import styled from '@emotion/styled';
import { useBreakpoints } from '../../hooks/useBreakpoints';

export type FooterRowProps = {
  blocks: FooterBlockProps[];
  social: SocialMediaBlockProps;
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 50px 60px 0 60px;
  flex-direction: column;
  border-top: solid black 1px;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-right: 50px;
  }
`;

const FooterBlocks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    align-items: flex-start;
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const FooterRow: React.FC<FooterRowProps> = props => {
  const { isDesktop } = useBreakpoints();

  return (
    <Wrapper>
      <InnerWrapper>
        <FooterBlocks>
          {props.blocks.map(block => (
            <FooterBlock key={block.header.title} {...block} />
          ))}
        </FooterBlocks>

        {!isDesktop && <SocialMediaBlock {...props.social} />}
      </InnerWrapper>

      {isDesktop && <SocialMediaBlock {...props.social} />}
    </Wrapper>
  );
};
