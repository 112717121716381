import React from 'react';
import styled from '@emotion/styled';

export type YoutubeEmbedProps = {
  videoId: string;
  title?: string;
  width?: string;
  height?: string;
  displayMode?: 'fixed' | 'fluid';
  keepMediaRatio?: boolean;
};

const StyledIframe = styled.iframe<Pick<YoutubeEmbedProps, 'displayMode' | 'keepMediaRatio'>>`
  display: block;
  margin: auto;
  max-height: 100%;

  ${({ displayMode }) =>
    displayMode === 'fixed' &&
    `
    max-width: 100%;
  `}

  ${({ displayMode, theme, keepMediaRatio }) =>
    displayMode === 'fluid' &&
    `
    width: 100%;

    @media screen and (min-width: ${theme.breakpoint.desktop}) {
      height: 100%;
      ${keepMediaRatio && `position: absolute;`}
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `}
`;

export const YoutubeEmbed: React.FC<YoutubeEmbedProps> = props => {
  const src = `https://www.youtube-nocookie.com/embed/${props.videoId}`;

  return (
    <StyledIframe
      title={props.title}
      src={src}
      width={props.width}
      height={props.height}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      displayMode={props.displayMode}
      keepMediaRatio={props.keepMediaRatio}
      className="optanon-category-C0002-C0004"
    />
  );
};

YoutubeEmbed.defaultProps = {
  title: 'Youtube video',
  width: '560',
  height: '315',
  displayMode: 'fixed',
  keepMediaRatio: false,
};
