import React from 'react';
import { css, Theme } from '@emotion/react';
import { graphql } from 'gatsby';
import { useTheme } from '@emotion/react';
import { SmartLink } from '../atoms/smart-link';
import { Icon, IconProps } from '../atoms/icon';
import { Link } from '../../types/links';
import { useIntlLink } from '../../hooks/useIntlLink';

const sizes = {
  'xx-small': '184px',
  'x-small': '260px',
  small: '315px',
  medium: '335px',
  large: '375px',
  'x-large': '400px',
  full: '100%',
};

export type CtaProps = {
  text: string;
  title?: string;
  size?: keyof typeof sizes;
  icon?: IconProps['kind'];
  iconHidden?: boolean;
  onClick?: () => void;
  link?: Link;
  className?: string;
  openInNewTab?: boolean;
};

const style = (props: CtaProps, theme: Theme) => css`
  color: ${theme.color.text.cta};
  stroke: ${theme.color.text.cta};

  background-color: ${theme.color.primary};
  width: ${sizes.full};
  max-width: 100%;
  text-transform: uppercase;
  font-size: ${theme.font.size.cta};
  height: 50px;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  cursor: pointer;

  transition: color, background-color 200ms linear;

  :focus,
  :active,
  :hover {
    color: ${theme.color.primary};
    stroke: ${theme.color.primary};
    background-color: ${theme.color.secondary};
  }

  @media screen and (min-width: ${theme.breakpoint.desktop}) {
    width: ${sizes[props.size ?? 'medium']};
  }
`;

export const CTA: React.FC<CtaProps> = props => {
  const theme = useTheme();

  const children = (
    <>
      {props.text}
      {!props.iconHidden && <Icon strokeColor="inherit" kind={props.icon ?? 'right-arrow'} />}
    </>
  );

  const { localizeLink } = useIntlLink();
  const link = localizeLink(props.link ?? '');

  if (link) {
    return (
      <SmartLink
        link={link}
        title={props.title || props.text}
        css={style(props, theme)}
        className={props.className}
        openInNewTab={props.openInNewTab}
        onClick={props.onClick}
      >
        {children}
      </SmartLink>
    );
  } else {
    return (
      <button
        onClick={props.onClick}
        title={props.title || props.text}
        css={style(props, theme)}
        className={props.className}
      >
        {children}
      </button>
    );
  }
};

CTA.defaultProps = {
  icon: 'right-arrow',
  iconHidden: false,
  size: 'medium',
  openInNewTab: false,
};

export const linkFragment = graphql`
  fragment Link on cms_ComponentAtomLink {
    text
    url
    link_title
    article {
      ...ArticleLink
    }
    program {
      ...ProgramLink
    }
    internal_link {
      Slug
    }
    generic_page {
      ...GenericPageLink
    }
    open_in_new_tab
  }
`;

export const linkUtilsFragments = graphql`
  fragment ArticleLink on cms_Article {
    slug
    excerpt
    thumbnail {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    thumbnail_mobile {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    title
    tags {
      name
    }
  }

  fragment ProgramLink on cms_Program {
    slug
    excerpt
    thumbnail {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    thumbnail_mobile {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    title
    tags {
      name
    }
  }

  fragment GenericPageLink on cms_GenericPage {
    slug
    excerpt
    thumbnail {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    thumbnail_mobile {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    title
    tags {
      name
    }
  }
`;
