import React from 'react';
import { Heading } from '../atoms/heading';
import { CTA, CtaProps } from './cta';
import { SocialButton, SocialKind } from './social-button';

import styled from '@emotion/styled';
import { Image, ImageProps } from '../atoms/image';
import { SmartLink } from '../atoms/smart-link';
import { useIntlLink } from '../../hooks/useIntlLink';

export type NavMenuProps = {
  className?: string;
  category?: CtaProps;
  title: string;
  cta?: CtaProps;
  links: CtaProps[];
  onNavigation?: () => void;
  aside?: {
    img?: ImageProps;
    content: string;
    link?: CtaProps;
    tags?: string[];
    socials?: {
      [key in SocialKind]: string;
    };
  };
};

const GrayColorWrapper = styled.div`
  background: transparent;
  padding: 0;
  z-index: ${({ theme }) => theme.zindex.header};

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    background: ${({ theme }) => theme.color.palette['gray-lighten-3']};
    padding: 40px ${({ theme }) => theme.spacing.header};
    min-height: 378px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    background: ${({ theme }) => theme.color.palette['gray-lighten-3']};
    display: flex;
    flex-direction: row;
    padding: 0;
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  text-transform: uppercase;
  margin-bottom: 10px;

  h1 {
    max-width: 280px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-right: 48px;
    margin-bottom: 0;
    max-width: 335px;
  }
`;

const LinksWrapper = styled.ul`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    padding-bottom: 30px;

    a {
      text-decoration: none;
      color: black;
    }
    a:hover {
      color: #3a2da6;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding: 0 48px;
    min-width: 335px;

    li {
      padding-bottom: 0;
    }

    li + li {
      padding-top: 40px;
    }
  }
`;

const AsideWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  flex: 0 0 auto;
  height: 100%;

  background: ${({ theme }) => theme.color.palette['base-purple']};

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-left: 48px;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 17px;
  width: auto;
  height: 168px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding: 16px 24px;
    width: 275px;
    height: 275px;
  }

  p {
    margin-top: 5px;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.color.palette['pure-black']};
  }

  div {
    display: flex;
  }
`;

export const NavMenu: React.FC<NavMenuProps> = props => {
  const { localizeLink } = useIntlLink();
  const link = localizeLink(props.aside?.link?.link ?? '');

  return (
    <GrayColorWrapper {...props}>
      <Wrapper>
        {props.cta && (
          <CTAWrapper>
            <Heading level="h2" text={props.title} />
            <CTA {...props.cta} onClick={props.onNavigation} />
          </CTAWrapper>
        )}

        <LinksWrapper>
          {props.links.map(menuLink => {
            const link = localizeLink(menuLink.link ?? '');
            return (
              <li key={link}>
                <SmartLink
                  onClick={props.onNavigation}
                  link={link}
                  title={menuLink.title || menuLink.text}
                  text={menuLink.text}
                  openInNewTab
                />
              </li>
            );
          })}
        </LinksWrapper>

        {props.aside && (
          <div>
            <AsideWrapper title={props.aside.link?.title}>
              <SmartLink link={link}>
                <Image {...props.aside.img} />
              </SmartLink>
              <StyledContent>
                <p>{props.aside.content}</p>
                <div>
                  <SocialButton kind="instagram" linkValue={props.aside.socials?.instagram ?? ''} />
                  <SocialButton kind="facebook" linkValue={props.aside.socials?.facebook ?? ''} />
                  <SocialButton kind="twitter" linkValue={props.aside.socials?.twitter ?? ''} />
                </div>
              </StyledContent>
            </AsideWrapper>
          </div>
        )}
      </Wrapper>
    </GrayColorWrapper>
  );
};
