import { AvailableLocales } from '../locale';
import { ImageProps } from '../components/atoms/image';
import { Cms_UploadFile, RelatedContentFragment } from '../../graphql-types';
import { DeepPartial } from '../utils/types';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export function mapFragmentToImageProps(
  fragment: DeepPartial<Cms_UploadFile> | null | undefined,
  locale: AvailableLocales,
  fallback?: DeepPartial<Cms_UploadFile> | null | undefined
): ImageProps | undefined {
  if (!fragment) {
    return undefined;
  }

  return {
    id: fragment.id,
    alt: getLocalizedStringOfSingleField(fragment.alternativeText, locale),
    title: getLocalizedStringOfSingleField(fragment.caption, locale),
    gatsbyImageData: extractImageData(fragment),
    ...(fallback && {
      mobile: {
        gatsbyImageData: extractImageData(fallback),
      },
    }),
  };
}

function extractImageData(fragment: DeepPartial<Cms_UploadFile>): IGatsbyImageData | undefined {
  return (fragment?.staticFile?.childImageSharp?.gatsbyImageData ?? undefined) as IGatsbyImageData | undefined;
}

export function mapRelatedContentFragmentToImgProps(
  content: RelatedContentFragment,
  locale: AvailableLocales
): ImageProps | undefined {
  if (content.illustration) {
    return mapFragmentToImageProps(content.illustration, locale);
  }

  if (content.article?.thumbnail) {
    return mapFragmentToImageProps(content.article.thumbnail, locale);
  }

  if (content.program?.thumbnail) {
    return mapFragmentToImageProps(content.program.thumbnail, locale);
  }
}

function getLocalizedStringOfSingleField(field: string | null | undefined, locale: AvailableLocales): string {
  if (!field) {
    return '';
  }

  if (!field.includes(';')) {
    return field;
  }

  const [en, fr] = field.split(';');

  switch (locale) {
    case 'en':
      return en;
    case 'fr':
      return fr;
  }
}
