import React from 'react';

import styled from '@emotion/styled';

export type ProgressBarProps = {
  scroll: number;
};

const Wrapper = styled.div`
  position: relative;
  height: 5px;
  z-index: ${({ theme }) => theme.zindex.header};
  background: ${({ theme }) => theme.color.palette['base-blue']};
`;

export const ProgressBar: React.FC<ProgressBarProps> = props => {
  const width = `${props.scroll}%`;
  return <Wrapper style={{ width: width }} />;
};

export default ProgressBar;
