import React, { createContext, useContext, useEffect, useState } from 'react';

import { BreadcrumbsProps } from '../components/molecules/breadcrumbs';

type BreadcrumbContext = {
  breadcrumbs: BreadcrumbsProps;
  setBreadcrumbs: (breadcrumbs: BreadcrumbsProps) => void;
};

export const BreadcrumbsContext = createContext<BreadcrumbContext>({ breadcrumbs: {}, setBreadcrumbs: () => {} });

export const useBreadcrumbs = (value: BreadcrumbsProps): void => {
  const { setBreadcrumbs } = useContext(BreadcrumbsContext);

  useEffect(() => {
    setBreadcrumbs(value);
    return () => setBreadcrumbs({});
  }, []); // eslint-disable-line
};

export const BreadcrumbsProvider: React.FC = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState({});
  const value = { breadcrumbs, setBreadcrumbs };

  return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
};
