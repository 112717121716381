import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import { css } from '@emotion/react';
import { SmartLink } from './smart-link';
import { NavMenu, NavMenuProps } from '../molecules/nav-menu';
import styled from '@emotion/styled';
import { useIntlLink } from '../../hooks/useIntlLink';

export type NavMenuTitleProps = {
  menu: NavMenuProps;
};

const StyledLink = styled(SmartLink)`
  display: inline-block;
  color: ${({ theme }) => theme.color.text.body};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
  &:active {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const NavMenuTitle: React.FC<NavMenuTitleProps> = ({ menu }) => {
  const theme = useTheme();
  const [isActive, setIsActive] = useState(false);

  const active = () => setIsActive(true);
  const inactive = () => setIsActive(false);
  let timeOutId: undefined | NodeJS.Timeout = undefined;

  const onBlurHandler = () => {
    timeOutId = setTimeout(() => {
      inactive();
    }, 200);
  };

  // If a child receives focus, do not close the popover.
  const onFocusHandler = () => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }

    active();
  };

  const showHoverMenu = menu.cta || menu.aside || menu.links.length > 0;
  const { localizeLink } = useIntlLink();
  const link = localizeLink(menu?.category?.link ?? '');

  return (
    <div
      css={css`
        height: 106px;
        display: flex;
        align-items: center;
      `}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onMouseOver={onFocusHandler}
      onMouseOut={onBlurHandler}
      aria-label={menu?.category?.title || menu?.category?.text}
      role="menuitem"
      tabIndex={0}
      aria-haspopup="true"
      aria-expanded={isActive}
    >
      <StyledLink
        link={link}
        title={menu?.category?.title || menu?.category?.text}
        openInNewTab={menu?.category?.openInNewTab}
      >
        <span>{menu?.category?.text}</span>
      </StyledLink>

      {showHoverMenu && (
        <NavMenu
          css={css`
            display: ${isActive ? 'block' : 'none'};
            position: absolute;
            width: 100%;
            top: ${theme.spacing.headerHeight};
            left: 0;
            box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.25);
          `}
          {...menu}
        />
      )}
    </div>
  );
};
