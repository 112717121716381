import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { graphql } from 'gatsby';

import { theme } from '../../theme';
import { GlobalStyles } from './global-styles';
import { ScrollToTop } from '../molecules/scroll-to-top';
import { BreadcrumbsProvider } from '../../hooks/useBreadcrumbs';
import { LocalizedSlugsProvider } from '../../hooks/useLocalizedSlugs';
import { NavBar, NavBarProps } from '../molecules/navbar';
import { Footer, FooterProps } from '../organisms/footer';

type LayoutBaseProps = {
  menu?: NavBarProps;
  footer?: FooterProps;
};

export const LayoutBase: React.FC<LayoutBaseProps> = ({ children, menu, footer }) => {
  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T8SNWCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>

      <ThemeProvider theme={theme}>
        <BreadcrumbsProvider>
          <LocalizedSlugsProvider>
            <GlobalStyles />
            {menu && <NavBar {...menu} />}
            <main>{children}</main>
            <ScrollToTop />
            {footer && <Footer {...footer} />}
          </LocalizedSlugsProvider>
        </BreadcrumbsProvider>
      </ThemeProvider>
    </>
  );
};

export const menuFragment = graphql`
  fragment Menu on cms_Menu {
    logo {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(width: 133, height: 60, placeholder: NONE, layout: FIXED)
        }
      }
    }
    RightCTA {
      ...Link
    }
    MenuCategory {
      title
      CTA {
        ...Link
      }
      Category {
        ...Link
      }
      Subcategories {
        ...Link
      }
      RelatedContent {
        ...RelatedContent
      }
    }
  }

  fragment Footer on cms_Footer {
    FooterNavbar {
      link {
        ...Link
      }
      FooterNavbarLinks {
        ...Link
      }
    }
  }

  fragment SocialGlobalSettings on cms_GlobalSettings {
    ContactAddress
    FacebookPageId
    InstagramAccount
    TwitterAccount
  }
`;
