module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/usr/app/src/components/layout/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kering foundation","short_name":"Kering foundation","description":"Launched in 2009, the Kering Corporate Foundation combats Violence Against Women. Since its inception, more than 140,000 women benefited from its support.","lang":"en","start_url":"/en/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/favicon-32x32.png","icons":[{"src":"./android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"./android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"./maskable_icon.png","sizes":"196x196","type":"image/png","purpose":"maskable"}],"localize":[{"start_url":"/fr/","lang":"fr","name":"Fondation Kering","short_name":"Fondation Kering","description":"Fondée en 2009, la Kering Corporate Foundation combat la violence envers les femmes. Depuis sa création, plus de 140 000 femmes ont bénéficié de son action."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3a7390970d10feba7d5064c20a69cbc3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/usr/app/src/intl","languages":["en","fr"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
