import React from 'react';

import { LayoutFr } from './layout-fr';
import { LayoutEn } from './layout-en';
import { AvailableLocales } from '../../locale';

type LayoutProps = {
  pageContext: { language: AvailableLocales };
};

const Layout: React.FC<LayoutProps> = ({ children, pageContext }) => {
  if (pageContext.language === 'fr') {
    return <LayoutFr>{children}</LayoutFr>;
  }

  return <LayoutEn>{children}</LayoutEn>;
};

export default Layout;
