import { rem } from 'polished';

const palette = {
  'pure-black': '#000000',
  'pure-white': '#FFFFFF',
  'base-blue': '#402FC9',
  'blue-darken-1': '#3728AC',
  'blue-darken-2': '#3326A2',
  'base-brown': '#614E45',
  'gray-darken-3': '#17181A',
  'gray-darken-2': '#1D1D1B',
  'gray-darken-1': '#C0C0C0',
  'base-gray': '#D8D8D8',
  'base-gray-2': '#979797',
  'gray-lighten-1': '#E1E1E1',
  'gray-lighten-2': '#F2F2F2',
  'gray-lighten-3': '#F5F5F5',
  'base-purple': '#ECDDFD',
  'base-purple-2': '#3A2DA6',
  'base-yellow': '#F7F1D4',
  'base-pink': '#FDC2B8',
  'pink-lighten-1': '#FEEBDE',
  'pink-lighten-2': '#FEF1E9',
  'pink-lighten-3': '#FBF1EA',
  'pink-lighten-4': '#F5EBE2',
  'pink-lighten-5': '#F9F1EB',
};

const breakpoints = {
  desktop: 1200,
  tablet: 800,
  largeMobile: 500,
  smallMobile: 320,
};

export const theme = {
  font: {
    family: {
      header: 'Prompt, sans-serif',
      text: 'Lato, sans-serif',
    },
    size: {
      default: rem('16px'),
      cta: rem('14px'),
      h1: rem('36px'),
      h2: rem('30px'),
      h3: rem('21px'),
      h4: rem('18px'),
      kpi: rem('50px'),
    },
    lineHeight: {
      default: rem('23px'),
      cta: rem('20px'),
      h1: rem('43px'),
      h2: rem('37px'),
      h3: rem('28px'),
      h4: rem('23px'),
      kpi: rem('65px'),
    },
  },
  color: {
    palette,
    text: {
      body: palette['pure-black'],
      cta: palette['pure-white'],
      link: palette['base-blue'],
      inactive: palette['base-gray'],
    },
    underline: {
      primary: palette['pure-black'],
      secondary: palette['base-blue'],
    },
    icons: {
      stroke: {
        blue: palette['base-blue'],
        white: palette['pure-white'],
      },
      background: {
        blue: palette['base-blue'],
        purple: palette['base-purple'],
      },
    },
    primary: palette['base-blue'],
    secondary: palette['base-purple'],
  },
  breakpoint: {
    desktop: rem(breakpoints.desktop),
    desktopInPx: breakpoints.desktop,
    tablet: rem(breakpoints.tablet),
    tabletInPx: breakpoints.tablet,
    largeMobile: rem(breakpoints.largeMobile),
    largeMobileInPx: breakpoints.largeMobile,
    smallMobile: rem(breakpoints.smallMobile),
    smallMobileInPx: breakpoints.smallMobile,
  },
  zindex: {
    header: 50,
    scrollToTop: 30,
    submenu: 10,
  },
  spacing: {
    unit: 10,
    headerHeight: rem('105px'),
    headerHeightMobile: rem('67px'),
    sectionGap: rem('70px'),
    sectionGapMobile: rem('25px'),
    body: rem('100px'),
    bodyMobile: rem('20px'),
    header: rem('60px'),
    editorialContent: rem('24px'),
    breadcrumbs: rem('55px'),
    howWeWorkSubMenu: rem('90px'),
  },
};

export type Theme = typeof theme;
