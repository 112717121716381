import { IntlShape } from 'gatsby-plugin-react-intl';
import { SocialMediaIconProps } from '../components/molecules/social-media-block';
import { safeApply, safeMap } from './utils';
import { FooterFragment, SocialGlobalSettingsFragment } from '../../graphql-types';
import { FooterProps } from '../components/organisms/footer';
import { mapFragmentToCtaProps } from './cta.mapper';

export function mapFooterFragmentToFooterProps(
  footerFragment: FooterFragment,
  globalSetting: SocialGlobalSettingsFragment,
  intl: IntlShape
): FooterProps {
  const icons: SocialMediaIconProps[] = [];

  if (globalSetting?.TwitterAccount) {
    icons.push({
      kind: 'twitter',
      link: `https://twitter.com/${globalSetting.TwitterAccount}`,
      title: intl.formatMessage({ id: 'follow_us_on' }, { network: 'Twitter' }),
    });
  }

  if (globalSetting?.InstagramAccount) {
    icons.push({
      kind: 'instagram',
      link: `https://www.instagram.com/${globalSetting.InstagramAccount}`,
      title: intl.formatMessage({ id: 'follow_us_on' }, { network: 'Instagram' }),
    });
  }

  if (globalSetting?.FacebookPageId) {
    icons.push({
      kind: 'facebook',
      link: `https://www.facebook.com/${globalSetting.FacebookPageId}`,
      title: intl.formatMessage({ id: 'follow_us_on' }, { network: 'Facebook' }),
    });
  }
  const bookmark = {
    contactAddress: globalSetting?.ContactAddress ?? '',
  };

  const footerRow = {
    social: {
      icons,
    },
    blocks: safeMap(navbar => {
      const cta = safeApply(link => mapFragmentToCtaProps(link), navbar.link);

      return {
        header: {
          url: cta?.link ?? '',
          title: cta?.title ?? '',
          text: cta?.text ?? '',
        },
        links: safeMap(link => {
          const cta = mapFragmentToCtaProps(link);

          return {
            url: cta?.link ?? '',
            title: cta?.text ?? '',
            text: cta?.text ?? '',
          };
        }, navbar?.FooterNavbarLinks),
      };
    }, footerFragment?.FooterNavbar),
  };

  return { footerRow, bookmark };
}
