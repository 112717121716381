import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { Icon } from '../atoms/icon';
import { ProgressBar } from '../atoms/progress-bar';
import { RoundIcon } from '../atoms/round-icon';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useProgress } from '../../hooks/useProgress';
import { SmartLink } from '../atoms/smart-link';
import { useTimedSwitch } from '../../hooks/useTimedSwitch';
import { isDOM } from '../../utils/links';
import { transformTextBreak } from '../../utils/markdown-renderer';

export type BreadcrumbsProps = {
  contentCategory?: string;
  contentCategoryUrl?: string;
  pageTitle?: string;
  pageUrl?: string;
  relatedWebsiteUrl?: string;
  showProgressBar?: boolean;
};

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.palette['base-purple']};
  padding: 0 ${({ theme }) => theme.spacing.unit * 6}px;
  font-family: ${({ theme }) => theme.font.family.header};
  font-weight: 500;

  @media (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding: 0 ${({ theme }) => theme.spacing.unit * 1.5}px;
  }
`;

const Spacer = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex: 1;
  }
`;

const ShareBtn = styled.button`
  display: flex;
  text-transform: uppercase;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    i {
      margin-right: 10px;
    }
  }
  color: ${({ theme }) => theme.color.palette['base-blue']};
`;

const BreadcrumbText = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex: 4;
    justify-content: flex-start;
  }
`;

const BackMenuWrapper = styled.div`
  display: flex;
  min-height: 50px;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  button {
    cursor: pointer;
  }
`;

const ShareMenuWrapper = styled.div`
  cursor: pointer;
  margin-left: 10px;
  button {
    cursor: pointer;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-left: 60px;
  }
`;

const ShareIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-left: 20px;
  }
`;

const VisitWebsiteWrapper = styled.div`
  display: flex;
  align-items: center;
  a,
  a:visited,
  a:hover,
  a:active {
    display: flex;
    text-decoration: none;
    color: ${({ theme }) => theme.color.palette['base-blue']};
    text-transform: uppercase;
    white-space: nowrap;

    @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
      i {
        margin-right: 10px;
      }
    }
  }
`;

const EndSpacer = styled(Spacer)`
  justify-content: flex-end;
  flex: 1;
  margin-left: 15px;
`;

const StyledPageTitle = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.color.palette['base-blue']};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledCategoryContent = styled.span`
  margin-right: 8px;
`;

const ClipboardButton = styled.button`
  padding: 0;
  margin-left: 20px;
  cursor: pointer;
`;

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  contentCategory,
  contentCategoryUrl,
  pageTitle,
  pageUrl,
  relatedWebsiteUrl,
  showProgressBar,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const blue = theme.color.palette['base-blue'];

  const { isDesktop } = useBreakpoints();
  const scroll = useProgress();

  const [isShareMenuOpen, setShareMenuOpen] = useState<boolean>(false);
  const [isCopyActive, activatedCopy] = useTimedSwitch(1000);
  const openMenuHandler = () => setShareMenuOpen(true);
  const closeMenuHandler = () => setShareMenuOpen(false);

  const urlToShare = pageUrl ?? isDOM() ? window.location.href : '';

  const twitterLink = {
    url: `https://www.twitter.com/share?url=${urlToShare}`,
    title: intl.formatMessage({ id: 'share_twitter' }),
  };

  const facebookLink = {
    url: `https://www.facebook.com/sharer.php?u=${urlToShare}`,
    title: intl.formatMessage({ id: 'share_facebook' }),
  };

  const pageTitleFormatted = transformTextBreak(pageTitle, true);
  const showBreadcrumbs = pageTitleFormatted || contentCategory || relatedWebsiteUrl;

  if (!showBreadcrumbs) return null;

  return (
    <Wrapper>
      <Row>
        <Spacer>
          {isShareMenuOpen && (
            <BackMenuWrapper onClick={closeMenuHandler}>
              <button>
                <Icon kind="left-arrow" strokeColor={blue} />
              </button>
            </BackMenuWrapper>
          )}
        </Spacer>
        <BreadcrumbText>
          {!isShareMenuOpen && (
            <>
              <SmartLink link={contentCategoryUrl}>
                <StyledCategoryContent>{contentCategory}</StyledCategoryContent>
              </SmartLink>
              {contentCategory && pageTitleFormatted && <span>|</span>}
              <StyledPageTitle>{pageTitleFormatted}</StyledPageTitle>
            </>
          )}
        </BreadcrumbText>
        <EndSpacer>
          {!isShareMenuOpen && (
            <>
              {relatedWebsiteUrl && (
                <VisitWebsiteWrapper>
                  <a href={relatedWebsiteUrl} target="_blank" rel="noreferrer">
                    <Icon kind="open-link" strokeColor={blue} />
                    {isDesktop && intl.formatMessage({ id: 'visit_website' })}
                  </a>
                </VisitWebsiteWrapper>
              )}
              <ShareMenuWrapper onClick={openMenuHandler}>
                <ShareBtn>
                  <Icon kind="share" strokeColor={blue} fillColor={blue} />
                  {isDesktop && intl.formatMessage({ id: 'share' })}
                </ShareBtn>
              </ShareMenuWrapper>
            </>
          )}

          {isShareMenuOpen && (
            <ShareIconsWrapper>
              <a href={twitterLink.url} title={twitterLink.title} target="_blank" rel="noreferrer">
                <RoundIcon kind="twitter" />
              </a>
              <a href={facebookLink.url} title={facebookLink.title} target="_blank" rel="noreferrer">
                <RoundIcon kind="facebook" />
              </a>
              {!isCopyActive && (
                <CopyToClipboard text={urlToShare}>
                  <ClipboardButton
                    disabled={isCopyActive}
                    title={intl.formatMessage({ id: 'copy_link' })}
                    onClick={activatedCopy}
                  >
                    <RoundIcon kind="copy-link" />
                  </ClipboardButton>
                </CopyToClipboard>
              )}
              {isCopyActive && <span style={{ color: blue }}>{intl.formatMessage({ id: 'copied' })}</span>}
            </ShareIconsWrapper>
          )}
        </EndSpacer>
      </Row>
      {showProgressBar && <ProgressBar scroll={scroll} />}
    </Wrapper>
  );
};

Breadcrumbs.defaultProps = {
  showProgressBar: true,
};
