import { LinkFragment, RelatedContentFragment } from '../../graphql-types';
import { CtaProps } from '../components/molecules/cta';

export function mapFragmentToCtaProps(fragment: LinkFragment): CtaProps | undefined {
  const {
    text,
    internal_link: internalLink,
    url: externalLink,
    article,
    program,
    generic_page: genericPage,
  } = fragment;

  const linkSlug = (internalLink?.Slug || genericPage?.slug || externalLink || article?.slug || program?.slug) ?? '';
  const linkType = externalLink || internalLink || genericPage?.slug ? 'link' : article?.slug ? 'news' : 'programs';

  if (!text || !linkSlug) {
    return undefined;
  }

  return {
    link: {
      type: linkType,
      slug: linkSlug,
    },
    text,
    title: fragment.link_title || text || '',
    openInNewTab: fragment.open_in_new_tab ?? false,
  };
}

export function mapRelatedContentFragmentToCtaProps(fragment: RelatedContentFragment): CtaProps {
  const { instagram_text, instagram_story, article, program } = fragment;

  const linkType = instagram_story ? 'link' : article?.slug ? 'news' : program?.slug ? 'programs' : '';
  const linkSlug = (instagram_story || article?.slug || program?.slug) ?? '';

  return {
    ...(linkType && {
      link: {
        type: linkType,
        slug: linkSlug,
      },
    }),
    text: instagram_text ?? '',
    title: fragment.link_title || instagram_text || '',
    openInNewTab: fragment.open_in_new_tab ?? false,
  };
}
