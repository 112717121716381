import React from 'react';
import { Heading } from '../atoms/heading';
import { RoundIcon } from '../atoms/round-icon';
import styled from '@emotion/styled';
import { useIntl } from 'gatsby-plugin-react-intl';

const Wrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  width: 257px;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-left: 50px;
    align-items: flex-start;
    margin-bottom: 50px;
  }
`;

const IconsBlockWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  margin-top: 20px;
`;

const IconWrapper = styled.a``;

export type SocialMediaIconProps = {
  kind: 'facebook' | 'instagram' | 'twitter';
  title?: string;
  link: string;
};

const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({ kind, link, title }) => {
  return (
    <IconWrapper href={link} title={title} target="_blank">
      <RoundIcon kind={kind} />
    </IconWrapper>
  );
};

export type SocialMediaBlockProps = {
  icons: SocialMediaIconProps[];
};

export const SocialMediaBlock: React.FC<SocialMediaBlockProps> = props => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Heading level="h4" noMargin>
        {intl.formatMessage({ id: 'follow_us' })}
      </Heading>

      <IconsBlockWrapper>
        {props.icons.map(icon => (
          <SocialMediaIcon key={icon.kind} {...icon} />
        ))}
      </IconsBlockWrapper>
    </Wrapper>
  );
};
