import { AvailableLocales } from '../locale';
import { Cms_UploadFile } from '../../graphql-types';
import { DeepPartial } from '../utils/types';
import { MediaProps } from '../components/molecules/media';
import { mapFragmentToImageProps } from './image.mapper';

export function mapFragmentToMediaProps(
  imageFragment: DeepPartial<Cms_UploadFile> | null | undefined,
  youtubeEmbedId: string,
  locale: AvailableLocales,
  fallback?: DeepPartial<Cms_UploadFile> | null | undefined
): MediaProps | undefined {
  if (!imageFragment && !youtubeEmbedId) {
    return undefined;
  }

  if (youtubeEmbedId) {
    return {
      type: 'youtube',
      youtube: {
        videoId: youtubeEmbedId,
      },
    };
  }

  return {
    type: 'image',
    image: mapFragmentToImageProps(imageFragment, locale, fallback),
  };
}
