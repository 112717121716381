import React from 'react';
import { theme } from '../../theme';
import styled from '@emotion/styled';
import { SmartLink, SmartLinkProps } from './smart-link';
import { Link } from '../../types/links';
import { useIntlLink } from '../../hooks/useIntlLink';

export type CTALinkProps = {
  text: string;
  color?: 'body' | 'link' | 'inactive';
  hasUnderline?: boolean;
  size?: 'default' | 'cta';
  underline?: 'primary' | 'secondary';
  uppercase?: boolean;
  link?: Link;
  language?: string;
  openInNewTab?: boolean;
  hover?: boolean;
};

const getColor = (color: CTALinkProps['color']) => theme.color.text[color ?? 'body'];

const getUnderlineColor = (underline: CTALinkProps['underline']) => theme.color.underline[underline ?? 'secondary'];

const getFontSize = (size: CTALinkProps['size']) => theme.font.size[size ?? 'default'];

export const CTALink: React.FC<CTALinkProps> = ({
  color,
  text,
  underline,
  size,
  link,
  hasUnderline,
  uppercase,
  openInNewTab,
  hover,
  ...rest
}) => {
  const P = styled.span`
    ${hasUnderline ? `border-bottom: solid ${getUnderlineColor(underline)} 2px;` : ''}
    ${uppercase && `text-transform: uppercase;`}
    font-size: ${getFontSize(size)};
    display: inline;
    padding-bottom: 2px;
    margin: 0;

    transition: color 200ms linear;

    :focus,
    :active,
    :hover {
      ${hover ? `color: ${theme.color.primary}` : ''}
    }
  `;

  const { localizeLink } = useIntlLink();
  const ctaLink = localizeLink(link ?? '');

  const StyledSmartLink = styled(SmartLink)<SmartLinkProps>`
    color: ${getColor(color)};
  `;

  return (
    <StyledSmartLink link={ctaLink} openInNewTab={openInNewTab} {...rest}>
      <P>{text}</P>
    </StyledSmartLink>
  );
};

CTALink.defaultProps = {
  color: 'body',
  hasUnderline: true,
  size: 'default',
  underline: 'secondary',
  openInNewTab: false,
  hover: true,
};
