import { MenuFragment, SocialGlobalSettingsFragment } from '../../graphql-types';
import { NavBarProps } from '../components/molecules/navbar';
import { AvailableLocales } from '../locale';
import { mapFragmentToCtaProps, mapRelatedContentFragmentToCtaProps } from './cta.mapper';
import { mapRelatedContentFragmentToTagListProps } from './homepage.mapper';
import { mapFragmentToImageProps, mapRelatedContentFragmentToImgProps } from './image.mapper';
import { safeApply, safeMap } from './utils';

export function mapMenuFragmentToNavBarProps(
  fragment: MenuFragment,
  globalSetting: SocialGlobalSettingsFragment,
  locale: AvailableLocales
): NavBarProps {
  const cta = safeApply(value => mapFragmentToCtaProps(value), fragment?.RightCTA);
  const logo = mapFragmentToImageProps(fragment?.logo, locale);

  const menu = safeMap(entry => {
    return {
      title: entry.title ?? '',
      category: safeApply(value => mapFragmentToCtaProps(value), entry.Category),
      cta: safeApply(value => mapFragmentToCtaProps(value), entry.CTA),
      links: safeMap(subcategory => mapFragmentToCtaProps(subcategory), entry.Subcategories),
      aside: entry.RelatedContent
        ? {
            img: mapRelatedContentFragmentToImgProps(entry.RelatedContent, locale),
            content: entry.RelatedContent.instagram_text ?? '',
            link: mapRelatedContentFragmentToCtaProps(entry.RelatedContent) ?? '',
            tags: mapRelatedContentFragmentToTagListProps(entry.RelatedContent) ?? '',
            socials: {
              instagram: (entry.RelatedContent.show_instagram && globalSetting.InstagramAccount) || '',
              facebook: (entry.RelatedContent.show_facebook && globalSetting.FacebookPageId) || '',
              twitter: (entry.RelatedContent.show_twitter && globalSetting.TwitterAccount) || '',
            },
          }
        : undefined,
    };
  }, fragment?.MenuCategory);

  return { cta, logo, menu };
}
