import React from 'react';
import styled from '@emotion/styled';
import BurgerMenuIcon from '../../assets/burger-menu.svg';

type BurgerMenuProps = {
  onClick?: () => void;
};

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ onClick }) => {
  const BurgerWrap = styled.i`
    display: none;

    @media (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: auto;
      margin-left: 24px;
      cursor: pointer;
      svg {
        display: block;
      }
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.smallMobile}) {
      margin-left: 15px;
    }
  `;

  return (
    <BurgerWrap onClick={onClick}>
      <BurgerMenuIcon />
    </BurgerWrap>
  );
};
