import React, { CSSProperties } from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import { isExternalLink, isInPageLink } from '../../utils/links';

import styled from '@emotion/styled';

export type SmartLinkProps = {
  text?: string;
  title?: string;
  link?: string;
  openInNewTab?: boolean;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.color.palette['base-purple-2']};
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: black;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.color.palette['base-purple-2']};
  }
`;

const InPageLink = styled(ExternalLink)``;

export const SmartLink: React.FC<SmartLinkProps> = ({
  link,
  title,
  text,
  openInNewTab,
  className,
  children,
  onClick,
  ...rest
}) => {
  if (!link) {
    return <span className={className}>{children || text}</span>;
  }

  return isExternalLink(link) ? (
    <ExternalLink
      href={link}
      title={title}
      target={openInNewTab ? '_blank' : '_self'}
      rel="noreferrer"
      className={className}
      onClick={onClick}
    >
      {children || text}
    </ExternalLink>
  ) : isInPageLink(link) ? (
    <InPageLink href={link} title={title} className={className} onClick={onClick} {...rest}>
      {children || text}
    </InPageLink>
  ) : (
    <StyledLink to={link} title={title} className={className} onClick={onClick} {...rest}>
      {children || text}
    </StyledLink>
  );
};
