// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-dynamic-pages-article-tsx": () => import("./../../../src/dynamic-pages/article.tsx" /* webpackChunkName: "component---src-dynamic-pages-article-tsx" */),
  "component---src-dynamic-pages-donate-to-our-partners-category-tsx": () => import("./../../../src/dynamic-pages/donate-to-our-partners-category.tsx" /* webpackChunkName: "component---src-dynamic-pages-donate-to-our-partners-category-tsx" */),
  "component---src-dynamic-pages-generic-page-tsx": () => import("./../../../src/dynamic-pages/generic-page.tsx" /* webpackChunkName: "component---src-dynamic-pages-generic-page-tsx" */),
  "component---src-dynamic-pages-newsroom-tsx": () => import("./../../../src/dynamic-pages/newsroom.tsx" /* webpackChunkName: "component---src-dynamic-pages-newsroom-tsx" */),
  "component---src-dynamic-pages-program-tsx": () => import("./../../../src/dynamic-pages/program.tsx" /* webpackChunkName: "component---src-dynamic-pages-program-tsx" */),
  "component---src-dynamic-pages-programs-tsx": () => import("./../../../src/dynamic-pages/programs.tsx" /* webpackChunkName: "component---src-dynamic-pages-programs-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-donate-to-our-partners-tsx": () => import("./../../../src/pages/donate-to-our-partners.tsx" /* webpackChunkName: "component---src-pages-donate-to-our-partners-tsx" */),
  "component---src-pages-get-involved-tsx": () => import("./../../../src/pages/get-involved.tsx" /* webpackChunkName: "component---src-pages-get-involved-tsx" */),
  "component---src-pages-governance-tsx": () => import("./../../../src/pages/governance.tsx" /* webpackChunkName: "component---src-pages-governance-tsx" */),
  "component---src-pages-how-we-work-with-our-partners-tsx": () => import("./../../../src/pages/how-we-work-with-our-partners.tsx" /* webpackChunkName: "component---src-pages-how-we-work-with-our-partners-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kering-foundation-tsx": () => import("./../../../src/pages/kering-foundation.tsx" /* webpackChunkName: "component---src-pages-kering-foundation-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-our-impact-tsx": () => import("./../../../src/pages/our-impact.tsx" /* webpackChunkName: "component---src-pages-our-impact-tsx" */),
  "component---src-pages-our-mission-tsx": () => import("./../../../src/pages/our-mission.tsx" /* webpackChunkName: "component---src-pages-our-mission-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-programs-tsx": () => import("./../../../src/pages/programs.tsx" /* webpackChunkName: "component---src-pages-programs-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

