import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Icon } from '../atoms/icon';

const StyledButton = styled.button`
  display: none;
  cursor: pointer;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    display: block;
    position: fixed;
    bottom: 120px;
    right: 60px;
    z-index: ${({ theme }) => theme.zindex.scrollToTop};

    transform: translateY(220px);
    transition: all 200ms ease-in-out;
    opacity: 0;

    &.show {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

function useIsScrolled() {
  const [isScrolled, setIsScrolled] = useState(false);
  const onWindowScroll = useDebouncedCallback(() => {
    setIsScrolled(window.scrollY > 200);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll.callback);
    return () => window.removeEventListener('scroll', onWindowScroll.callback);
  });

  return isScrolled;
}

export const ScrollToTop: React.FC = () => {
  const isScrolled = useIsScrolled();
  const onButtonClick = useCallback(() => window.scrollTo(0, 0), []);

  return (
    <StyledButton aria-hidden="true" tabIndex={-1} onClick={onButtonClick} className={isScrolled ? 'show' : ''}>
      <Icon strokeColor="black" kind="arrow-up-circle" />
    </StyledButton>
  );
};
