import React from 'react';
import GithubSlugger from 'github-slugger';
import ReactMarkdown, { NodeType, Position } from 'react-markdown';
import { YoutubeEmbed } from '../components/atoms/youtube-embed';
import { SmartLink } from '../components/atoms/smart-link';

type ReactMarkdownRendererProps = {
  node: {
    type: NodeType;
    children: {
      position: Position;
      value: string;
    }[];
  };
};

export const HeadingRenderer: React.ElementType<ReactMarkdownRendererProps & { level: number }> = props => {
  const slugger = new GithubSlugger();
  const slug = slugger.slug(props.node?.children?.[0]?.value);

  return React.createElement('h' + props.level, { id: slug }, props.children);
};

const YOUTUBE_EMBED_RE = [
  /^https:\/\/(?:\w+\.)?youtube\.com\/watch\?v=(?<videoId>.+)$/,
  /^https:\/\/(?:\w+\.)?youtube\.com\/v\/(?<videoId>.+)$/,
  /^https:\/\/youtu\.be\/(?<videoId>.+)$/,
];

const FallbackRender: React.ElementType<ReactMarkdownRendererProps> = props => {
  const renderer = ReactMarkdown.renderers[props.node.type];

  if (typeof renderer === 'string') {
    return React.createElement(renderer, {}, props.children);
  }

  return <>{renderer(props)}</>;
};

export const EmbedRenderer: React.ElementType<ReactMarkdownRendererProps> = props => {
  const child = props.node.children[0];

  const isText = child.position.start.column === 1;
  const content = child.value;

  const result = content && YOUTUBE_EMBED_RE.map(re => content.match(re)).find(Boolean);

  if (!isText || !result || !result.groups?.videoId) {
    return FallbackRender(props);
  }

  return <YoutubeEmbed videoId={result.groups.videoId} />;
};

export const LINE_BREAK_RE = /\s*<br\s*\/?>\s*/gi;

export function transformTextBreak(text: string | undefined, removeLinebreak: boolean): string {
  if (!text) {
    return '';
  }

  const linebreak = removeLinebreak ? ' ' : '&NewLine;';

  return text.replace(LINE_BREAK_RE, linebreak);
}

export const LinkRenderer: React.ElementType<ReactMarkdownRendererProps & { href: string }> = props => {
  return (
    <SmartLink link={props.href} openInNewTab={true}>
      {props.children}
    </SmartLink>
  );
};
