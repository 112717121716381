import React from 'react';

import styled from '@emotion/styled';
import { FooterBookmark, FooterBookmarkProps } from '../molecules/footer-bookmark';
import { FooterRow, FooterRowProps } from '../molecules/footer-row';
import { PostFooter } from '../molecules/post-footer';

export type FooterProps = {
  footerRow: FooterRowProps;
  bookmark: FooterBookmarkProps;
};

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
`;

export const Footer: React.FC<FooterProps> = ({ footerRow, bookmark }) => {
  return (
    <Wrapper>
      <FooterRow {...footerRow} />
      <FooterBookmark {...bookmark} />
      <PostFooter />
    </Wrapper>
  );
};
