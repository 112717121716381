import { useState, useEffect, useCallback } from 'react';

type TimedSwitch = [boolean, () => void];

export function useTimedSwitch(delayMs: number): TimedSwitch {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      const timeoutId = setTimeout(() => setIsActive(false), delayMs);
      return () => clearTimeout(timeoutId);
    }
  }, [isActive, delayMs]);

  const activate = useCallback(() => setIsActive(true), []);

  return [isActive, activate];
}
