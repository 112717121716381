import React, { useContext } from 'react';
import { CTALink } from '../atoms/cta-link';
import styled from '@emotion/styled';
import { useIntl } from 'gatsby-plugin-react-intl';
import { LocalizedSlugsContext, LocalizedSlug } from '../../hooks/useLocalizedSlugs';
import { AvailableLocales } from '../../locale';

const LocaleLink = ({ locale, selected }: { locale: string; selected: boolean }) => {
  const intl = useIntl();
  const currentLocale = intl.locale as AvailableLocales;
  const { localizedSlugs } = useContext(LocalizedSlugsContext);

  type LocalizedLink = { locale: string; link: string };

  const generateLocalizedLinks = (slugs: LocalizedSlug[] | undefined): LocalizedLink[] => {
    const localizedLinks: LocalizedLink[] = [];
    slugs?.forEach(slug => {
      const localizedLink = { locale: slug.locale, link: `/${slug.slug}` };
      localizedLinks.push(localizedLink);
    });
    return localizedLinks;
  };

  const localizedLinks = generateLocalizedLinks(localizedSlugs.slugs);

  const link = localizedLinks.find(link => link.locale === locale)?.link;

  const existingLocales: string[] | undefined = localizedSlugs.slugs?.map(element => element.locale);

  // If the alt content doesn't exist, the alt locale link is grayed out and doesn't react to hover
  const color = currentLocale !== locale && !existingLocales?.includes(locale) ? 'inactive' : 'body';

  const hover = currentLocale !== locale && !existingLocales?.includes(locale) ? false : true;

  return (
    <CTALink
      color={color}
      size="default"
      text={locale.toUpperCase()}
      underline="primary"
      hasUnderline={selected}
      language={locale}
      link={link}
      hover={hover}
    />
  );
};

const LocalesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  > * + * {
    margin-left: 30px;
  }
`;

export const Locales: React.FC = props => {
  const intl = useIntl();

  return (
    <LocalesWrapper>
      <LocaleLink locale="en" selected={intl.locale === 'en'} />
      <LocaleLink locale="fr" selected={intl.locale === 'fr'} />
    </LocalesWrapper>
  );
};
