import React, { createContext, useContext, useEffect, useState } from 'react';

export type LocalizedSlug = {
  locale: string;
  slug: string;
};

type LocalizedSlugProps = {
  slugs?: LocalizedSlug[];
};

type LocalizedSlugContext = {
  localizedSlugs: LocalizedSlugProps;
  setLocalizedSlugs: (localizedSlugs: LocalizedSlugProps) => void;
};

export const LocalizedSlugsContext = createContext<LocalizedSlugContext>({
  localizedSlugs: {},
  setLocalizedSlugs: () => {},
});

export const useLocalizedSlugs = (value: LocalizedSlugProps): void => {
  const { setLocalizedSlugs } = useContext(LocalizedSlugsContext);

  useEffect(() => {
    setLocalizedSlugs(value);
    return () => setLocalizedSlugs({});
  }, []); // eslint-disable-line
};

export const LocalizedSlugsProvider: React.FC = ({ children }) => {
  const [localizedSlugs, setLocalizedSlugs] = useState({});
  const value = { localizedSlugs, setLocalizedSlugs };

  return <LocalizedSlugsContext.Provider value={value}>{children}</LocalizedSlugsContext.Provider>;
};
