import React from 'react';
import { Global, css } from '@emotion/react';
import { normalize } from 'polished';

export const GlobalStyles: React.FC = () => (
  <Global
    styles={theme => css`
      @import url('https://fonts.googleapis.com/css2?family=Lato&family=Prompt&display=swap');

      ${normalize()}

      html,
      body {
        font-family: ${theme.font.family.text};
        font-size: ${theme.font.size.default};
        line-height: ${theme.font.lineHeight.default};
        font-weight: 400;
        color: ${theme.color.text.body};
        box-sizing: border-box;
        scroll-behavior: smooth;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      body {
        max-width: 1440px;
        margin: 0 auto !important;
      }

      /* HTML selector defaults  */
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0;
      }

      button: {
        font-size: ${theme.font.size.cta};
        line-height: ${theme.font.lineHeight.cta};
      }

      h1 {
        font-family: ${theme.font.family.header};
        font-size: ${theme.font.size.h1};
        font-weight: 400;
        line-height: ${theme.font.lineHeight.h1};
        text-transform: uppercase;
      }

      h2 {
        font-family: ${theme.font.family.header};
        font-size: ${theme.font.size.h2};
        font-weight: 400;
        line-height: ${theme.font.lineHeight.h2};
        text-transform: uppercase;
      }

      h3 {
        font-family: ${theme.font.family.header};
        font-size: ${theme.font.size.h3};
        line-height: ${theme.font.lineHeight.h3};
      }

      h4 {
        font-family: ${theme.font.family.text};
        font-size: ${theme.font.size.h4};
        line-height: ${theme.font.lineHeight.h4};
      }

      blockquote {
        font-size: ${theme.font.size.h3};
        font-family: ${theme.font.family.header};
        margin-left: 0;
        margin-right: 0;
      }

      a {
        text-decoration: none;
      }

      figure {
        margin: 0;
      }

      img {
        max-width: 100%;
      }

      button {
        background: none;
        border: none;
      }
    `}
  />
);
