export const isExternalLink = (link: string): boolean => {
  // A valid external link should start with http(s):// or www.
  const externalUrl = /^(https?:\/\/|www\.)/i;
  const mailTo = /^mailto:/;

  return externalUrl.test(link) || mailTo.test(link);
};

export const isInPageLink = (link: string): boolean => {
  return link.startsWith('#');
};

const prependInternalLink = (link: string) => {
  // Ensure all internal links have a full path and are not relative to current page
  return link.startsWith('/') ? link : `/${link}`;
};

export const safeLink = (link: string): string => {
  return isExternalLink(link) ? link : prependInternalLink(link);
};

export const isDOM = (): boolean => {
  return typeof window !== `undefined`;
};
