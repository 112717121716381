import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  padding: 0 ${({ theme }) => theme.spacing.header};
  border-top: solid black 1px;

  a {
    border-bottom: 2px solid black;
    padding-bottom: 2px;
    color: black;
  }

  a: hover {
    color: ${({ theme }) => theme.color.palette['base-purple-2']};
    border-color: ${({ theme }) => theme.color.palette['base-purple-2']};
  }
`;

export const PostFooter: React.FC = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <p>
        {intl.formatMessage({ id: 'footer.kering_group' })}{' '}
        <a
          href="https://www.kering.com"
          title={intl.formatMessage({ id: 'footer.kering_group_title' })}
          target="_blank"
          rel="noreferrer"
        >
          www.kering.com
        </a>
      </p>
    </Wrapper>
  );
};
