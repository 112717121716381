import React from 'react';

import { RoundIcon } from '../atoms/round-icon';
import styled from '@emotion/styled';

export type SocialKind = 'twitter' | 'instagram' | 'facebook';

export type SocialButtonProps = {
  kind: SocialKind;
  linkValue: string;
  className?: string;
  text?: string;
};

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;

  color: ${({ theme }) => theme.color.text.body};

  i {
    margin-right: ${({ theme }) => theme.spacing.unit}px;
  }
`;

const getSocialLink = (kind: SocialKind, linkValue: string): string => {
  const socialKindLinkMapper = {
    instagram: `https://www.instagram.com/${linkValue}`,
    facebook: `https://www.facebook.com/${linkValue}`,
    twitter: `https://twitter.com/${linkValue}`,
  };
  return socialKindLinkMapper[kind];
};

export const SocialButton: React.FC<SocialButtonProps> = ({ kind, linkValue, className, text }) => {
  if (!linkValue) return null;

  return (
    <StyledLink className={className} href={getSocialLink(kind, linkValue)} rel="me" target="_blank">
      <RoundIcon kind={kind} radius={18} />
      {text && <span>{text}</span>}
    </StyledLink>
  );
};
