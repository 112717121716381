import React from 'react';
import styled from '@emotion/styled';

import Twitter from '../../assets/twitter.svg';
import Instagram from '../../assets/instagram.svg';
import Facebook from '../../assets/facebook.svg';
import CopyLink from '../../assets/icon-copy-link.svg';

const getRoundIcon = (kind: RoundIconProps['kind']): JSX.Element => {
  switch (kind) {
    case 'twitter':
      return <Twitter />;
    case 'instagram':
      return <Instagram />;
    case 'facebook':
      return <Facebook />;
    case 'copy-link':
      return <CopyLink />;
  }
};

export type RoundIconProps = {
  kind: 'twitter' | 'instagram' | 'facebook' | 'copy-link';
  radius?: number;
  hasFocus?: boolean;
};

const Wrapper = styled.i<Pick<RoundIconProps, 'hasFocus' | 'radius'>>`
  padding: 5px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: inline-flex;

  ${({ radius }) =>
    radius &&
    `
    width: ${radius * 2}px;
    height: ${radius * 2}px;
  `}

  transition: background-color 200ms linear;
  background-color: ${({ theme, hasFocus }) => (hasFocus ? theme.color.primary : theme.color.secondary)};

  svg {
    display: block;
    transition: fill 200ms linear;
    fill: ${({ theme, hasFocus }) => (hasFocus ? theme.color.palette['pure-white'] : theme.color.primary)};
    padding: 5px;
  }

  :focus,
  :active,
  :hover {
    background-color: ${({ theme }) => theme.color.primary};

    svg {
      fill: ${({ theme }) => theme.color.palette['pure-white']};
    }
  }
`;

export const RoundIcon: React.FC<RoundIconProps> = ({ kind, radius, hasFocus }) => {
  const getIcon = () => (
    <Wrapper radius={radius} hasFocus={hasFocus} aria-hidden={true}>
      {getRoundIcon(kind)}
    </Wrapper>
  );

  return getIcon();
};

RoundIcon.defaultProps = {
  hasFocus: false,
};
