import { AvailableLocales } from '../locale';

import {
  ArticleLinkFragment,
  ArticleListFragment,
  ProgramLinkFragment,
  Cms_Tag,
  HomepageTextContentWithMediaFragment,
  InstagramStoriesFragment,
  LargeProgramFragment,
  RelatedContentFragment,
  SimpleImageCardFragment,
  SimpleImageCardListFragment,
  RelatedContentHeaderFragment,
} from '../../graphql-types';

import { InstagramStoriesProps } from '../components/organisms/instagram-stories';
import { SimpleCardProps } from '../components/molecules/simple-card';
import { EditorialContentThumbnailProps } from '../components/molecules/editorial-content-thumbnail';
import { EditorialContentListProps } from '../components/molecules/editorial-content-list';
import { HomepageTextContentWithMediaProps } from '../components/molecules/text-content-with-media';
import { TitleCtaProps } from '../components/molecules/title-cta';

import { mapFragmentToCtaProps } from './cta.mapper';
import { mapFragmentToImageProps } from './image.mapper';
import { mapFragmentToMediaProps } from './media.mapper';
import { MediaCardProps } from '../components/organisms/media-card';
import { SimpleCardListProps } from '../components/organisms/simple-card-list';
import { safeApply, safeMap } from './utils';

export function mapFragmentToInstagramStoriesProps(
  fragment: InstagramStoriesFragment,
  locale: AvailableLocales
): InstagramStoriesProps {
  return {
    title: fragment.text_before_instagram ?? '',
    images: safeMap(story => mapFragmentToImageProps(story, locale), fragment.stories),
  };
}

export function mapLargeProgramFragmentToMediaCardProps(
  fragment: LargeProgramFragment,
  locale: AvailableLocales
): MediaCardProps {
  const mediaImage = mapFragmentToImageProps(fragment?.program?.thumbnail, locale, fragment?.program?.thumbnail_mobile);

  const media = mediaImage ? { type: 'image' as const, image: mediaImage } : undefined;

  return {
    media,
    title: fragment?.program?.title ?? '',
    tags: safeMap(tag => tag.name, fragment?.program?.tags),
    ctaLink: {
      link: {
        type: 'programs',
        slug: fragment?.program?.slug ?? '',
      },
    },
    excerpt: fragment?.program?.excerpt ?? '',
  };
}

export function mapFragmentToSimpleCardListProps(
  fragments: SimpleImageCardListFragment,
  locale: AvailableLocales
): SimpleCardListProps {
  return {
    cards: safeMap(fragment => mapFragmentToSimpleCardProps(fragment, locale), fragments.SimpleCard),
  };
}

export function mapFragmentToContentImageProps(
  fragment: HomepageTextContentWithMediaFragment,
  locale: AvailableLocales
): HomepageTextContentWithMediaProps {
  return {
    contentOrder: fragment.order ?? 'text_image',
    media: mapFragmentToMediaProps(fragment.media, fragment.youtube_embed_id ?? '', locale, fragment.media_mobile),
    title: fragment.title ?? '',
    cta: safeApply(value => mapFragmentToCtaProps(value), fragment.link),
    markdown: fragment.markdown ?? '',
    backgroundColor: fragment.background_color ?? 'purple',
    textAlign: fragment.text_align ?? 'left',
  };
}

export function mapFragmentToEditorialContentListProps(
  articles: ArticleLinkFragment[],
  locale: AvailableLocales
): EditorialContentListProps {
  return {
    thumbnails: safeMap(fragment => mapFragmentToEditorialContentThumbmailProps(fragment, 'news', locale), articles),
    showContentTags: true,
  };
}

export function mapFragmentToEditorialContentThumbmailProps(
  fragment: ArticleLinkFragment | ProgramLinkFragment,
  type: 'news' | 'programs',
  locale: AvailableLocales
): EditorialContentThumbnailProps {
  return {
    link: {
      type,
      slug: fragment?.slug ?? '',
    },
    title: fragment.title ?? '',
    tags: mapFragmentToTagList(fragment?.tags),
    image: mapFragmentToImageProps(fragment?.thumbnail, locale, fragment?.thumbnail_mobile),
    excerpt: fragment?.excerpt ?? '',
    showTags: true,
  };
}

export function mapFragmentToSimpleCardProps(
  fragment: SimpleImageCardFragment,
  locale: AvailableLocales
): SimpleCardProps {
  return {
    text: fragment.text ?? '',
    title: fragment.title ?? '',
    image: mapFragmentToImageProps(fragment.media, locale, fragment.mobile_media),
  };
}

export function mapFragmentToTagList(
  fragment: Array<Pick<Cms_Tag, 'name'> | null | undefined> | undefined | null
): string[] {
  return safeMap(tag => tag.name, fragment);
}

export function mapFragmentToTitleCtaProps(
  fragment: LargeProgramFragment | ArticleListFragment | SimpleImageCardListFragment | RelatedContentHeaderFragment
): TitleCtaProps {
  return {
    title: fragment.title ?? '',
    body: fragment.text ?? '',
    cta: safeApply(value => mapFragmentToCtaProps(value), fragment.link),
  };
}

export function mapRelatedContentFragmentToTagListProps(content: RelatedContentFragment): string[] {
  if (!content) return [];

  if (content.article) {
    return mapFragmentToTagList(content.article.tags);
  }

  if (content.program) {
    return mapFragmentToTagList(content.program.tags);
  }

  return [];
}
