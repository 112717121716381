import React from 'react';

import { Link } from 'gatsby-plugin-react-intl';
import styled from '@emotion/styled';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useIntlLink } from '../../hooks/useIntlLink';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.font.family.header};
  margin-bottom: 50px;
  padding: 0 ${({ theme }) => theme.spacing.header};
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;
    margin-top: 50px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    padding: 5px 0;
  }
`;

const StyledIntlLink = styled(Link)`
  text-decoration: none;
  color: black;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.color.palette['base-purple-2']};
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: black;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.color.palette['base-purple-2']};
  }
`;

const Copyright = styled.p`
  text-align: center;
`;

export type FooterBookmarkProps = {
  contactAddress?: string;
};

export const FooterBookmark: React.FC<FooterBookmarkProps> = props => {
  const intl = useIntl();
  const year = new Date().getFullYear();

  const contact = intl.formatMessage({ id: 'footer.contact_us' });
  const tnc = intl.formatMessage({ id: 'footer.terms_and_conditions' });
  const cookie = intl.formatMessage({ id: 'footer.cookie_policy' });
  const privacy = intl.formatMessage({ id: 'footer.privacy_policy' });
  const sitemap = intl.formatMessage({ id: 'footer.sitemap' });
  const accessibility = intl.formatMessage({ id: 'footer.accessibility' });

  const { localizeLink } = useIntlLink();

  return (
    <Wrapper>
      {props.contactAddress && (
        <p>
          <StyledLink href={`mailto:${props.contactAddress}`}>{contact}</StyledLink>
        </p>
      )}

      <p>
        <StyledIntlLink
          to={localizeLink({ type: 'accessibility' })}
          title={intl.formatMessage({ id: 'go_to' }, { page: accessibility })}
        >
          {accessibility}
        </StyledIntlLink>
      </p>

      <p>
        <StyledIntlLink
          to={localizeLink({ type: 'terms-and-conditions' })}
          title={intl.formatMessage({ id: 'go_to' }, { page: tnc })}
        >
          {tnc}
        </StyledIntlLink>
      </p>

      <p>
        <StyledLink
          as="button"
          title={intl.formatMessage({ id: 'footer.cookie_policy_title' })}
          className="ot-sdk-show-settings ot-sdk-show-settings-reset"
        >
          {cookie}
        </StyledLink>
      </p>

      <p>
        <StyledIntlLink
          to={localizeLink({ type: 'privacy-policy' })}
          title={intl.formatMessage({ id: 'go_to' }, { page: privacy })}
        >
          {privacy}
        </StyledIntlLink>
      </p>

      <p>
        <StyledLink href="/sitemap/sitemap-index.xml" title={intl.formatMessage({ id: 'go_to' }, { page: sitemap })}>
          {sitemap}
        </StyledLink>
      </p>
      <Copyright>{intl.formatMessage({ id: 'footer.copyright' }, { year })}</Copyright>
    </Wrapper>
  );
};
