import { useCallback } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { safeLink } from '../utils/links';
import { Link } from '../types/links';

export function useIntlLink(): { localizeLink: (link: Link) => string } {
  const intl = useIntl();

  const localizeLink = useCallback(
    (link: Link): string => {
      if (typeof link === 'string') {
        return link;
      }

      if (link.type === 'link') {
        return safeLink(link.slug ?? '');
      }

      return link.slug
        ? safeLink(intl.formatMessage({ id: `path.${link.type}-slug` }, { slug: link.slug }))
        : safeLink(intl.formatMessage({ id: `path.${link.type}` }));
    },
    [intl]
  );

  return { localizeLink };
}
