import React, { useRef, useState } from 'react';

import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { animated, useSpring } from 'react-spring';
import { Icon } from '../atoms/icon';
import { AlignedMarkdownProps } from '../atoms/aligned-markdown';
import { Heading } from '../atoms/heading';
import { SmartLink } from '../atoms/smart-link';
import { Link } from '../../types/links';
import { useIntlLink } from '../../hooks/useIntlLink';

const AccordionItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  padding: 29px 20px;
  border-top: 2px solid black;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;

  transition: border 250ms linear;

  cursor: pointer;

  a,
  & {
    color: ${({ theme }) => theme.color.text.body};
  }

  &.visible {
    border-bottom: 2px solid black;
    a,
    & {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

const AccordionContentWrapper = styled.div<Pick<AccordionContentProps, 'isVisible' | 'contentBackground'>>`
  display: flex;
  justify-content: center;
  background-color: ${({ contentBackground }) => contentBackground};
  width: auto;
  overflow-x: ${({ isVisible }) => (isVisible ? 'auto' : ' hidden')};
  overflow-y: hidden;
`;

type AccordionContentProps = {
  markdown?: AlignedMarkdownProps;
  contentBackground?: string;
  isVisible: boolean;
};

const AccordionItemContent: React.FC<AccordionContentProps> = ({ children, isVisible, contentBackground }) => {
  const ref = useRef<HTMLDivElement>(null);

  const targetStyle = isVisible
    ? { maxHeight: ref.current?.scrollHeight ?? 600, opacity: 1, marginBottom: 16, flex: 1 }
    : { maxHeight: 0, opacity: 0, marginBottom: 0 };

  const spring = useSpring(targetStyle);

  return (
    <AccordionContentWrapper isVisible={isVisible} contentBackground={contentBackground}>
      <animated.div style={spring as any} ref={ref}>
        {children}
      </animated.div>
    </AccordionContentWrapper>
  );
};

const AccordionItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
`;

export type AccordionItemProps = {
  title?: string;
  link?: Link;
  linkText?: string;
  children?: React.ReactElement;
  showContent?: boolean;
  isActive?: boolean;
  onItemClose?: () => void;
  onLinkClicked?: () => void;
  contentBackground?: string;
  hideTitleBorderBottom?: boolean;
};

export const AccordionItem: React.FC<AccordionItemProps> = props => {
  const icon = props.isActive ? 'minus' : 'plus';
  const { localizeLink } = useIntlLink();
  const link = localizeLink(props.link ?? '');

  return (
    <AccordionItemWrapper>
      {props.children && props.showContent ? (
        <>
          <AccordionItemTitle className={props.isActive ? 'visible' : ''} onClick={props.onItemClose}>
            <div onKeyDown={props.onLinkClicked} onClick={props.onLinkClicked} role="button" tabIndex={0}>
              {props?.link ? (
                <StyledLink link={link} title={props.title || props.linkText}>
                  <span>{props.title}</span>
                </StyledLink>
              ) : (
                <Heading level="h3" noMargin text={props.title} />
              )}
            </div>

            {props.children && <Icon kind={icon} strokeColor="inherit" />}
          </AccordionItemTitle>

          <AccordionItemContent isVisible={props.isActive || false} contentBackground={props.contentBackground}>
            {props.children}
          </AccordionItemContent>
        </>
      ) : (
        <>
          {link ? (
            <SmartLink onClick={props.onLinkClicked} link={link} title={props.title || props.linkText}>
              <AccordionItemTitle>
                <span>{props.title}</span>
              </AccordionItemTitle>
            </SmartLink>
          ) : (
            <AccordionItemTitle>
              <Heading level="h3" noMargin text={props.title} />
            </AccordionItemTitle>
          )}
        </>
      )}
    </AccordionItemWrapper>
  );
};

AccordionItem.defaultProps = {
  showContent: true,
};

type StyledLinkProps = {
  isActive?: boolean;
  theme?: Theme;
};

export const StyledLink = styled(SmartLink)<StyledLinkProps>`
  display: inline-block;
  text-decoration: none;
`;

const AccordionWrapper = styled.div<Pick<AccordionProps, 'overflow'>>`
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: ${({ overflow }) => overflow || 'scroll'};

  > div:nth-last-of-type(1) {
    border-bottom: 2px solid black;
  }
`;

export type AccordionProps = {
  className?: string;
  children: React.ReactElement[];
  onClose?: () => void;
  overflow?: 'scroll' | 'visible';
  itemsContentBackground?: string;
  hideTitleBorderBottom?: boolean;
};

export const Accordion: React.FC<AccordionProps> = ({
  className,
  children,
  onClose,
  overflow,
  itemsContentBackground,
  hideTitleBorderBottom,
}) => {
  const [activeMenu, setActiveMenu] = useState<number>(-1);
  const handleActiveItem = (value: number) => setActiveMenu(old => (old === value ? -1 : value));

  return (
    <AccordionWrapper className={className} overflow={overflow}>
      {children.map((accordionItem, index) => {
        const accordionItemsProps = {
          ...accordionItem.props,
          isActive: activeMenu === index,
          contentBackground: itemsContentBackground,
          onItemClose: () => handleActiveItem(index),
          onLinkClicked: onClose,
          hideTitleBorderBottom,
        };

        return <AccordionItem key={accordionItem.key} {...accordionItemsProps} />;
      })}
    </AccordionWrapper>
  );
};

Accordion.defaultProps = {
  children: [],
  itemsContentBackground: 'transparent',
  hideTitleBorderBottom: false,
};
