import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql, useStaticQuery } from 'gatsby';

import { LayoutEnQuery } from '../../../graphql-types';
import { LayoutBase } from './layout-base';
import { mapMenuFragmentToNavBarProps } from '../../data-mappers/navbar.mapper';
import { AvailableLocales } from '../../locale';
import { mapFooterFragmentToFooterProps } from '../../data-mappers/footer.mapper';
import { safeApply2 } from '../../data-mappers/utils';

export const LayoutEn: React.FC = ({ children }) => {
  const intl = useIntl();
  const locale = intl.locale as AvailableLocales;

  const { cms } = useStaticQuery<LayoutEnQuery>(graphql`
    query LayoutEn {
      cms {
        menu(locale: "en") {
          ...Menu
        }
        footer(locale: "en") {
          ...Footer
        }

        globalSetting {
          ...SocialGlobalSettings
        }
      }
    }
  `);

  const menu = safeApply2(
    (menu, globalSetting) => mapMenuFragmentToNavBarProps(menu, globalSetting, locale),
    cms.menu,
    cms.globalSetting
  );
  const footer = safeApply2(
    (footer, globalSetting) => mapFooterFragmentToFooterProps(footer, globalSetting, intl),
    cms.footer,
    cms.globalSetting
  );

  return (
    <LayoutBase menu={menu} footer={footer}>
      {children}
    </LayoutBase>
  );
};
