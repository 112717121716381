import React, { useContext } from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import { Locales } from './locales';

import { NavMenuTitle } from '../atoms/nav-menu-title';
import { CTA, CtaProps } from './cta';
import { NavMenu, NavMenuProps } from './nav-menu';
import { Image, ImageProps } from '../atoms/image';
import { Accordion, AccordionItem } from './accordion';
import { BurgerMenu } from '../atoms/burger-menu';

import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { Breadcrumbs } from './breadcrumbs';
import { BreadcrumbsContext } from '../../hooks/useBreadcrumbs';

const LocalesMobileWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 100vw;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.palette['pure-white']};
  z-index: ${({ theme }) => theme.zindex.header};
`;

export type NavBarProps = {
  logo?: ImageProps;
  cta?: CtaProps;
  menu: NavMenuProps[];
};

type NavLeftProps = Pick<NavBarProps, 'menu' | 'logo'> & {
  onNavigation: () => void;
};

type NavRightProps = Pick<NavBarProps, 'cta'> & {
  onNavigation: () => void;
  onMenuClicked: () => void;
};

const Header = styled.header`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.color.palette['pure-white']};
  z-index: ${({ theme }) => theme.zindex.header};
`;

const NavBarWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing.header};
  height: ${({ theme }) => theme.spacing.headerHeight};

  @media (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    height: ${({ theme }) => theme.spacing.headerHeightMobile};
    margin: 0;
    padding: 0 24px;
  }
`;

const StyledAccordion = styled(Accordion)`
  height: calc(100vh - 67px);
  max-height: calc(100vh - 67px);
`;

export const NavBar: React.FC<NavBarProps> = ({ cta, menu, logo }) => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoints();
  const [isMenuVisible, { toggle, unlock }] = useLockBodyScroll();

  const { breadcrumbs } = useContext(BreadcrumbsContext);

  const showBreadcrumbs = isDesktop || !isMenuVisible;
  const showMobileMenu = !isDesktop && isMenuVisible;

  return (
    <Header>
      <NavBarWrapper aria-label="Kering Foundation">
        <NavLeft menu={menu} logo={logo} onNavigation={unlock} />
        <NavRight cta={cta} onNavigation={unlock} onMenuClicked={toggle} />
      </NavBarWrapper>

      {showBreadcrumbs && <Breadcrumbs {...breadcrumbs} />}

      {showMobileMenu && (
        <>
          <StyledAccordion onClose={unlock} itemsContentBackground={theme.color.palette['gray-lighten-3']}>
            {menu.map((item, index) => (
              <AccordionItem
                key={index}
                title={item?.category?.text || item?.category?.title}
                link={item?.category?.link}
                linkText={item?.category?.text}
                showContent={!!item.cta || !!item?.aside || item?.links?.length > 0}
              >
                <NavMenu {...item} onNavigation={unlock} />
              </AccordionItem>
            ))}
          </StyledAccordion>
          <LocalesMobileWrapper>
            <Locales />
          </LocalesMobileWrapper>
        </>
      )}
    </Header>
  );
};

const MenuTitles = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 6 0 auto;

  text-decoration: none;
  list-style-type: none;
  padding-left: 0;
`;

const Logo = styled(Image)`
  margin-top: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    max-height: 39px;
    max-width: 86px;
  }
`;

const NavMenuTitleWrapper: React.FC = ({ children }) => {
  return <li role="none">{children}</li>;
};

const NavLeft: React.FC<NavLeftProps> = ({ menu, logo, onNavigation }) => {
  const { isDesktop } = useBreakpoints();

  return (
    <MenuTitles role="menubar" aria-label="Kering Foundation menu">
      <NavMenuTitleWrapper>
        <Link to="/" onClick={onNavigation}>
          <Logo {...logo} alt="Kering Foundation Logo" loading="eager" />
        </Link>
      </NavMenuTitleWrapper>

      {isDesktop &&
        menu.map(item => (
          <NavMenuTitleWrapper key={item.title}>
            <NavMenuTitle menu={item} />
          </NavMenuTitleWrapper>
        ))}
    </MenuTitles>
  );
};

const NavRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex: 4 0 auto;

    > * + * {
      margin-left: 60px;
    }
  }
`;

const StyledCTA = styled(CTA)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.largeMobile}) {
    padding: 0 10px;
  }
`;

const NavRight: React.FC<NavRightProps> = ({ cta, onNavigation, onMenuClicked }) => {
  const { isDesktop } = useBreakpoints();

  return (
    <NavRightWrapper>
      {isDesktop ? (
        <>
          {cta && <CTA size="x-small" {...cta} onClick={onNavigation} />}
          <Locales />
        </>
      ) : (
        <>
          {cta && <StyledCTA {...cta} onClick={onNavigation} iconHidden />}
          <BurgerMenu onClick={onMenuClicked} />
        </>
      )}
    </NavRightWrapper>
  );
};
