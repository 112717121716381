import { useTheme } from '@emotion/react';

import { useWindowDimensions } from './useWindowDimensions';

export type Breakpoints = {
  isMobile: boolean;
  isLargeMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export function useBreakpoints(): Breakpoints {
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const { desktopInPx, tabletInPx, largeMobileInPx } = theme.breakpoint;

  const isDesktop = width >= desktopInPx;
  const isTablet = width >= tabletInPx && width < desktopInPx;
  const isLargeMobile = width >= largeMobileInPx && width < tabletInPx;
  const isMobile = width < largeMobileInPx;

  return { isMobile, isLargeMobile, isTablet, isDesktop };
}
