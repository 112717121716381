import { useState, useEffect } from 'react';

function getScrollProgress() {
  const totalScroll = document.documentElement.scrollTop;
  const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  return (totalScroll / windowHeight) * 100;
}

export function useProgress(): number {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', () => setProgress(getScrollProgress()));

    return () => window.removeEventListener('scroll', () => setProgress(getScrollProgress()));
  }, []);

  return progress;
}
