/**
 * @name isPresent
 * @description ensure an array of T doesn't contains any null of undefined member
 * also provide a typeguard to inform typescript of the new type.
 * @example
 *
 * const arr = [1, null, 2, 3, undefined]; // (number | null | undefined)[]
 *
 * isPresent(arr);
 * -> [1, 2, 3] // number[]
 */
export function isPresent<T>(t: T | null | undefined): t is T {
  return t !== null && t !== undefined;
}

type Optional<T> = T | null | undefined;

/**
 * @name safeMap
 * @description Safely map over possibly undefined or null array, containing undefined or null values,
 * with a function accepting non nullable that can return return undefined or null.
 * Always return an array of the non nullable type returned by the mapper.
 *
 * @example
 *
 * type ICanBeUndefined = { a?: { b?: c?: number[] } };
 *
 * const empty: ICanBeUndefined = {};
 * safeMap(num => num + 10, empty?.a?.b?.c)
 * -> [] // number[]
 *
 * const withNull: ICanBeUndefined = { a: { b: { c: [ 'foo', 'bar', null ] } } };
 * safeMap(str => str.toUpperCase(), withNull?.a?.b?.c);
 * -> ['FOO', 'BAR'] // string[]
 *
 * const withMapperThatReturnNull: ICanBeUndefined = { a: { b: { c: ['foo', 'foobar'] } } };
 * safeMap(str => str.length > 3 ? str : undefined, withMapperThatReturnNull?.a?.b?.c);
 * -> ['foobar'] // string[]
 */
export function safeMap<Unsafe, Safe>(
  mapper: (value: Unsafe, index: number, array: Unsafe[]) => Safe | null | undefined,
  value: Optional<Optional<Unsafe>[]>
): Safe[] {
  if (!value) {
    return [];
  }

  return (
    value
      // Filter invalid values from CMS
      .filter(isPresent)
      .map(mapper)
      // Filter invalid values generated by some mapper
      .filter(isPresent)
  );
}

/**
 * @name safeApply
 * @description ensure a value is not null or undefined before calling the mapper
 * Return undefined if it's the case
 */
export function safeApply<Unsafe, Safe>(mapper: (value: Unsafe) => Safe, value: Optional<Unsafe>): Safe | undefined {
  if (!isPresent(value)) {
    return undefined;
  }

  return mapper(value);
}

/**
 * @name safeApply2
 * @description ensure a value is not null or undefined before calling the mapper
 * Return undefined if it's the case
 */
export function safeApply2<Unsafe1, Unsafe2, Safe>(
  mapper: (value1: Unsafe1, value2: Unsafe2) => Safe,
  value1: Optional<Unsafe1>,
  value2: Optional<Unsafe2>
): Safe | undefined {
  if (!isPresent(value1) || !isPresent(value2)) {
    return undefined;
  }

  return mapper(value1, value2);
}

/**
 * @name identity
 * @description return the first paramter as is
 */
export function identity<T>(I: T): T {
  return I;
}
