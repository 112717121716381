import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { SmartLink } from '../atoms/smart-link';
import { Heading } from '../atoms/heading';
import { Link } from '../../types/links';
import { useIntlLink } from '../../hooks/useIntlLink';

import styled from '@emotion/styled';

type LinkData = {
  title: string;
  text: string;
  url: Link;
};

export type FooterBlockProps = {
  header: LinkData;
  links: LinkData[];
};

const Wrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  width: 257px;
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    :not(:first-child) {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-top: 0;
    text-align: initial;
  }

  h4 {
    margin-bottom: 0;
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  padding-top: 10px;
  margin: 0;
`;

const P = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const LinkList: React.FC<Pick<FooterBlockProps, 'links'>> = ({ links }) => {
  const intl = useIntl();
  const { localizeLink } = useIntlLink();

  return (
    <List>
      {links?.map((link: LinkData) => (
        <SmartLink
          link={localizeLink(link.url)}
          key={link.title}
          title={intl.formatMessage({ id: 'go_to' }, { page: link.title })}
        >
          <P>{link.text}</P>
        </SmartLink>
      ))}
    </List>
  );
};

export const FooterBlock: React.FC<FooterBlockProps> = ({ header, links }) => {
  const intl = useIntl();
  const { localizeLink } = useIntlLink();
  const link = localizeLink(header.url);

  return (
    <Wrapper>
      <SmartLink link={link} title={header.title || intl.formatMessage({ id: 'go_to' }, { page: header.text })}>
        <Heading level="h4" noMargin>
          {header.text}
        </Heading>
      </SmartLink>
      <LinkList links={links} />
    </Wrapper>
  );
};
