import { useEffect, useMemo, useState } from 'react';

type Handlers = {
  toggle: () => void;
  lock: () => void;
  unlock: () => void;
};

export function useLockBodyScroll(): [boolean, Handlers] {
  const [isBodyLocked, setIsBodyLocked] = useState<boolean>(false);

  const handlers = useMemo(
    () => ({
      toggle: () => setIsBodyLocked(isBodyLocked => !isBodyLocked),
      lock: () => setIsBodyLocked(true),
      unlock: () => setIsBodyLocked(false),
    }),
    []
  );

  useEffect(() => {
    document.body.style.overflow = isBodyLocked ? 'hidden' : 'visible';
  }, [isBodyLocked]);

  return [isBodyLocked, handlers];
}
